<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="12">
            <a-form-item label="数量">
              <a-input placeholder="正数为进库，负数为出库" v-decorator="['count', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { exhibition_goods_list } from '@/api/exhibition_goods'
import { exhibition_warehouse_list } from '@/api/exhibition_warehouse'
import { bonded_commodity_list } from '@/api/bonded_commodity'
import { oss_token_list } from '@/api/oss_token'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
// 表单字段
const fields = ['id', 'cost', 'serial', 'tag_price', 'sales_price']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      model: {},
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      actionUrl: '',
      previewVisible: false,
      previewImage: '',
      ImgUrl: '',
      fileList: [],
      batchList: [],
      goodsList: [],
      warehouseList: [],
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries
    }
  },
  created () {
    this.fileList = []

    oss_token_list().then(({ data }) => {
      console.log(data, '我是返回值')
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  mounted () {
    this.getExhibitionGoods()
    this.getExhibitionWarehouse()
  },
  methods: {
    // goods
    handleaddGoodsChange (value) {
      console.log(value)
      this.model = this.goodsList.find((item) => {
        return item.id === value
      })
      this.getbatchCommodity(value)
    },
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      console.log('我要预览', file.preview)
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      console.log('我是图片', file)
      if (file.status === 'removed') {
        this.form.setFieldsValue({ img: '' })
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.form.setFieldsValue({ img: this.actionUrl + '/' + this.new_multipart_params.key })
      }
    },
    beforeUpload (file) {
      this.new_multipart_params.key = file.uid + '_' + file.name
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      return isJpgOrPng && isLt5M
    },
    handleCancel (file) {
      this.previewVisible = false
      console.log(file, '我是删除')
    },
    onChange (value) {
      console.log(value)
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    },
    getExhibitionGoods () {
      exhibition_goods_list().then(({ data }) => {
        console.log(data)
        this.goodsList = data.entries || []
      })
    },
    getExhibitionWarehouse () {
      exhibition_warehouse_list().then(({ data }) => {
        console.log(data)
        this.warehouseList = data.entries || []
      })
    },
    getbatchCommodity (bonded_goods_id) {
      bonded_commodity_list({ bonded_goods_id: bonded_goods_id }).then(({ data }) => {
        console.log(data)
        this.batchList = data.entries || []
        this.form.setFieldsValue({
          bonded_commodity_id: ''
        })
      })
    }
  }
}
</script>
