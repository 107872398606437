<template>
  <div>
    <!--    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="名称">
              <a-input v-model="queryParam.name" placeholder="请输入名称" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="编号">
              <a-input v-model="queryParam.code" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="条形码">
              <a-input v-model="queryParam.barcode" placeholder=""/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>-->
    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="img" slot-scope="img, record">
        <a-avatar
          v-if="(getFileSuffix(img) !== 'pdf') && img"
          slot="img"
          :src="img"
          shape="square"
          @click="openPreview(img)"
        />
        <a :href="img" v-if="getFileSuffix(img) === 'pdf'" target="_blank">{{ record.name }}</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a @click="handleRecord(record)">查看</a>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="图片错误" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateTransferForm'

import {
  normal_commodity_transfer_create,
  normal_commodity_transfer_list
} from '@/api/normal_commodity'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      previewvisible: false,
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          align: 'center',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '创建时间',
          customRender: (text) => text || '-',
          dataIndex: 'create_time'
        },
        {
          title: '操作人',
          align: 'center',
          customRender: (text) => text || '-',
          dataIndex: 'create_by'
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'count',
          customRender: (text) => text > 0 ? '进货:' + Math.abs(text) : '出货:' + Math.abs(text)
        }
      ],
      previewurl: '',
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('电费参数', parameter)
        return normal_commodity_transfer_list(Object.assign(parameter, this.queryParam, { normal_commodity_id: this.$route.query.id }))
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            return data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    console.log(this.$Dictionaries)
  },
  methods: {
    getFileSuffix: function (filename) {
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    checkFileType: function (suffix) {
      var type = ''
      switch (suffix) {
        case 'mp4':
          type = 'mp4'
          break
        case 'ppt':
        case 'pptx':
        case 'doc':
        case 'docx':
        case 'pdf':
          type = 'office'
          break
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'bmp':
        case 'gif':
          type = 'pic'
          break
      }
      return type
    },
    openPreview (url) {
      this.previewvisible = true
      this.previewurl = url
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleUpdate (record) {
      console.log(record)
      this.current_bill_id = record.id
      this.payvisible = true
    },
    handleDelet (record) {},
    handleRecord (record) {
      this.$emit('onRecord', record)
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          values.normal_commodity_id = this.$route.query.id
          // 新增
          normal_commodity_transfer_create(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            this.$refs.table.refresh()
          })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>
